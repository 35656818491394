<template>
    <div class="testBlockSampleSet" v-loading="loading">
        <div class="base-box">
            <h1 class="title">
                试块留样设置
            </h1>
        </div>
        <div class="content-wrapper">
            <div class="custom-content">
                <div class="select-box">
                    <el-select v-model="form.test_block_type" @change="testTypeChange">
                        <el-option
                            v-for="item in typeList"
                            :key="'type'+item.code"
                            :label="item.code_name"
                            :value="item.code"
                        ></el-option>
                    </el-select>
                </div>
                <div class="choose-check">
                    <div class="switch-item">
                        <span>{{ form.test_block_type_name }}留样设置区</span>
                        <el-switch
                            v-model="form.is_enable"
                            active-color="#13ce66"
                            active-text="启用"
                            :active-value="1"
                            :inactive-value="0"
                        >
                        </el-switch>
                    </div>
                </div>
                <div class="panel">
                    <div class="tips">
                        已选：{{ haveSelectStr }}
                    </div>
                    <div class="wrapper-list">
                        <div class="wrapper-item">
                            <label class="name">选择规格:</label>
                            <ul>
                                <li>
                                    <el-radio-group v-model="form.test_block_specs" @change="specChange">
                                        <el-radio v-for="item in specList" :key="'spec'+item.code" :label="item.code">
                                            {{ item.code_name }}
                                        </el-radio>
                                    </el-radio-group>
                                </li>
                            </ul>
                        </div>
                        <div class="wrapper-item">
                            <label class="name">选择龄期:</label>
                            <ul>
                                <li>
                                    <el-checkbox-group v-model="form.test_block_age" @change="ageChange">
                                        <el-checkbox v-for="item in ageList" :key="'age'+item.code" :label="item.code">
                                            {{ item.code_name }}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </li>
                            </ul>
                        </div>
                        <div class="wrapper-item">
                            <label class="name">试块组数:</label>
                            <ul>
                                <li>
                                    <el-radio-group v-model="form.sample_group_amount" @change="groupChange">
                                        <el-radio v-for="item in blockGroupList" :key="'group'+item.code" :label="item.code">
                                            {{ item.code_name }}
                                        </el-radio>
                                    </el-radio-group>
                                </li>
                                <li>
                                    <div class="code-label">
                                        试块组编号示例：KJSK202111-0001-W1
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="wrapper-item">
                            <label class="name">每组试块个数:</label>
                            <ul>
                                <li>
                                    <el-radio-group v-model="form.test_block_amount" @change="numChange">
                                        <el-radio v-for="item in blockNumList" :key="'num'+item.code" :label="item.code">
                                            {{ item.code_name }}
                                        </el-radio>
                                    </el-radio-group>
                                </li>
                                <li>
                                    <div class="code-label">
                                        试块编号示例：KJSK202111-0001-W101
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="btns">
                        <el-button type="primary" size="small" @click="save">
                            保存
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'test-block-sample-set',
    data() {
        return {
            loading: false,
            // 类型列表
            typeList: [],
            // 规格列表
            specList: [],
            // 龄期列表
            ageList: [],
            // 试块组数列表
            blockGroupList: [],
            // 试块个数
            blockNumList: [],
            form: {
                test_block_specs: null,
                test_block_specs_name: '',
                test_block_age: [],
                test_block_age_name: [],
                sample_group_amount: null,
                sample_group_amount_name: '',
                test_block_amount: null,
                test_block_amount_name: '',
                test_block_type: null,
                test_block_type_name: '',
                is_enable: null,
            },
            haveSelectStr: '',
        };
    },
    watch: {
        form: {
            handler: function () {
                const test_block_age_name = this.form.test_block_age_name.join('+');
                this.haveSelectStr = this.form.test_block_specs_name + '-' + test_block_age_name
                    + '-' + this.form.sample_group_amount_name + '-' + this.form.test_block_amount_name;
            },
            deep: true,
        },
    },
    created() {
        this.init();
    },
    methods: {

        async init() {
            this.loading = true;
            this.typeList = await this.$axios.$get('/interfaceApi/production/testblock/get_test_block_dic?group_code=test_block_type');
            if (this.typeList.length > 0) {
                this.form.test_block_type = this.typeList[0].code;
                this.form.test_block_type_name = this.typeList[0].code_name;
                await this.getData();
            }
            this.loading = false;
        },
        async getData() {
            this.loading = true;
            const res = await this.$axios.$get('/interfaceApi/production/testblock/load_sample_settings?test_block_type='
                + this.form.test_block_type);
            res.forEach(item => {
                item.sampleSetupList.forEach(setItem => {
                    setItem.code = setItem.code + '';
                });
                if (item.sample_settings_type === 1) {
                    this.specList = item.sampleSetupList;
                    if (item.default_value) {
                        this.form.test_block_specs = item.default_value;
                        this.specChange(item.default_value);
                    }
                } else if (item.sample_settings_type === 2) {
                    this.ageList = item.sampleSetupList;
                    if (item.default_value) {
                        this.form.test_block_age = item.default_value.split(',');
                        this.ageChange(this.form.test_block_age);
                    }
                } else if (item.sample_settings_type === 3) {
                    this.blockGroupList = item.sampleSetupList;
                    if (item.default_value) {
                        this.form.sample_group_amount = item.default_value;
                        this.groupChange(item.default_value);
                    }
                } else if (item.sample_settings_type === 4) {
                    this.blockNumList = item.sampleSetupList;
                    if (item.default_value) {
                        this.form.test_block_amount = item.default_value;
                        this.numChange(item.default_value);
                    }

                }
                this.form.is_enable = item.is_enable;

            });
            this.loading = false;
        },
        async save() {
            if (!this.form.test_block_type_name) {
                this.$message.warning('请选择设置类型');
                return;
            }
            if (!this.form.test_block_specs_name) {
                this.$message.warning('请选择规格');
                return;
            }
            if (this.form.test_block_age_name.length === 0) {
                this.$message.warning('请选择龄期');
                return;
            }
            if (!this.form.sample_group_amount_name) {
                this.$message.warning('请选择试块组数');
                return;
            }
            if (!this.form.test_block_amount_name) {
                this.$message.warning('请选择每组试块个数');
                return;
            }
            const data = Object.assign({}, this.form);
            data.test_block_age = data.test_block_age.join(',');
            data.test_block_age_name = data.test_block_age_name.join(',');
            try {
                await this.$axios.$post('/interfaceApi/production/testblock/save_sample_settings', data);
                this.$message.success('操作成功');
            } catch (e) {
                this.$message.error( e.ErrorCode.Message);
            }
        },
        // 重置
        reset() {
            this.form = {
                test_block_specs: null,
                test_block_specs_name: '',
                test_block_age: [],
                test_block_age_name: [],
                sample_group_amount: null,
                sample_group_amount_name: '',
                test_block_amount: null,
                test_block_amount_name: '',
                test_block_type: this.form.test_block_type,
                test_block_type_name: this.form.test_block_type_name,
                is_enable: null,
            };
            this.getData();
        },
        // 类型变化
        testTypeChange(code) {
            const selectItem = this.typeList.find(item => item.code === code);
            this.form.test_block_type_name = selectItem.code_name;
            this.reset();
        },

        // 规格变化
        specChange(code) {
            const selectItem = this.specList.find(item => item.code === code);
            this.form.test_block_specs_name = selectItem.code_name;
        },

        // 龄期变化
        ageChange(val) {
            const code = [];
            const code_name = [];
            this.ageList.forEach(item => {
                if (val.includes(item.code)) {
                    code.push(item.code);
                    code_name.push(item.code_name);
                }
            });
            this.form.test_block_age = code;
            this.form.test_block_age_name = code_name;
        },

        // 试块组变化
        groupChange(code) {
            const selectItem = this.blockGroupList.find(item => item.code === code);
            this.form.sample_group_amount_name = selectItem.code_name;
        },


        // 每组试块个数变化
        numChange(code) {
            const selectItem = this.blockNumList.find(item => item.code === code);
            this.form.test_block_amount_name = selectItem.code_name;
        },
    },
};
</script>

<style lang="stylus" scoped>
    .testBlockSampleSet
        width 100%
        height 100%
        padding 0.18rem 0.3rem
        .btns
            padding-top 0.3rem
            padding-left 1.6rem
        .base-box
            position relative
            .title
                font-size 0.3rem
                color #333333
        .content-wrapper
            width 100%
            height calc(100% - 1.18rem)
            border-top 1px solid #F0F0F0
            .name
                padding-top 0.2rem
                padding-bottom 0.1rem
                color #666666
                font-size 0.16rem
            .custom-content
                height 100%
                .choose-check
                    padding 10px 0
                    display flex
                    .switch-item
                        display flex
                        align-items center
                        font-size 0.16rem
                        color #666
                        .el-switch
                            margin-left 0.1rem
                            >>> .el-switch__label.is-active
                                color: #21BC9A;
                .select-box
                    padding 0.2rem 0 0.1rem
                    .el-select
                        >>> .el-input__inner
                            width: 2.4rem;
                            height: 0.4rem;
                            background: #F5F5F5;
                            border-radius: 2px;
                            border: 1px solid #D0D0D0;
                            font-size 0.16rem
                            color #333333
                .panel
                    width 100%;
                    height calc(100% - 0.47rem)
                    border 1px solid #F0F0F0
                    overflow-y auto
                    .tips
                        padding-left 0.2rem
                        display flex
                        align-items center
                        height: 0.4rem;
                        background: #EDF8FF;
                        color #1890FF
                        font-size 0.16rem
                        .iconfont
                            font-size 0.16rem
                            margin-right 0.1rem
                    .wrapper-list
                        .wrapper-item
                            display flex
                            align-items: baseline;
                            .name
                                width 1.4rem
                                text-align right
                                font-size 0.16rem
                                color #666
                                flex-shrink: 0;
                            .el-radio-group
                                display flex
                                >>> .el-radio
                                    display flex
                                    align-items center
                                    margin-right 0.2rem
                                    margin-bottom 0.15rem
                                    .el-radio__label
                                        padding-left 0.03rem
                            ul
                                display flex
                                flex-direction column
                                padding-left 0.2rem
                                li
                                    display flex
                                    flex-wrap wrap
                                    justify-content flex-start
                                    .example
                                        width fit-content
                                        padding 0.02rem 0.03rem
                                        background: #F5F5F5;
                                        border-radius: 0.02rem;
                                        border: 1px solid #D0D0D0;
                                        color #999999
                                        font-size 0.14rem
                                    .input-box
                                        background: #FFFFFF;
                                        border-radius: 0.02rem;
                                        border: 1px solid #D0D0D0;
                                        display flex
                                        .tag
                                            display flex
                                            justify-content center
                                            align-items center
                                            width: 0.8rem;
                                            height: 0.38rem;
                                            background: #F5F5F5;
                                            border-right 1px solid #D0D0D0
                                            border-radius: 2px;
                                            font-size 0.14rem
                                            color #333333
                                        .input
                                            border 0
                                            width 2.4rem
                                            padding-left 0.06rem
                                            color #3333
                                            font-size 0.14rem
                                            &.input1
                                                width 1.8rem

                                    .code-label
                                        padding 0.08rem 0.08rem
                                        margin-left 0.04rem
                                        display flex
                                        justify-content center
                                        align-items center
                                        background: #E8F4FF;
                                        border-radius: 0.02rem;
                                        border: 1px solid #9ACEFD;
                                        color  #5483A6
                                        font-size 0.16rem

</style>
